<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "AppProductInfo",

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters(["mainLoading", "langs"]),
    ...mapState("Orders", {
      order: (state) => state.order.model,
      orderLoading: (state) => state.order.loading,
    }),

    /**
     * Verifica se as varições podem ser exibidas baseado nas seguintes regras
     *
     * - Não pode ser a pagina de avaliação
     * - Precisa existir alguma variação no produto
     *
     * @return {boolean}
     */
    shouldShowVariations() {
      return (
        this.$route.name !== "orders.rating" && this.product.sku?.length > 0
      );
    },

    /**
     * Verifica se deve exibir a label de presente.
     *
     * @return {boolean}
     */
    shouldShowGiftLabel() {
      const giftWrapList = this.order?.extensions?.GiftWrap || [];

      if (giftWrapList.length === 0) {
        return false;
      }

      return !!giftWrapList.find(
        (gitWrapItem) => gitWrapItem.product_id == this.product.id
      );
    },
  },
};
</script>

<template>
  <div class="app__order-products__product-details">
    <span
      class="app__order-products__product-name"
      :class="{ app__loading: loading }"
    >
      <template v-if="product.url">
        <a
          class="app__link"
          :href="product.url.https"
          :title="`Ir para ${product.name}`"
          target="_blank"
        >
          {{ product.name }}
        </a>
      </template>
      <template v-else>
        {{ product.name }}
      </template>
      <span
        data-test="gift-wrap-label"
        class="app__order-products__gift-wrap-label badge badge-secondary"
        v-show="shouldShowGiftLabel"
        :class="{ app__loading: loading }"
        >{{ this.langs.product["gift-wrap-label"] }}</span
      >
    </span>

    <div
      v-if="shouldShowVariations"
      class="app__order-products__product-variations"
    >
      <span
        class="app__order-products__product-variation"
        :class="{ app__loading: loading }"
        v-for="(variation, variationIndex) in product.sku"
        :key="variationIndex"
        :data-test="`variant-${variation.type.toLowerCase()}`"
      >
        {{ variation.type }}: {{ variation.value }}
      </span>
    </div>
  </div>
</template>
