<script>
import StarRating from "vue-star-rating";

export default {
  name: "AppStars",

  props: {
    starSize: {
      type: Number,
      default: 50,
    },
    rating: {
      type: Number,
      default: 0,
    },
    setRating: {
      type: Function,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    StarRating,
  },
};
</script>

<template>
  <section :class="{ app__loading: loading }" style="min-width: 120px">
    <star-rating
      v-show="!loading"
      id="star-component"
      :rating="rating"
      @update:rating="setRating"
      :star-size="this.starSize"
      :read-only="this.readOnly"
      border-color="#D2D2D2"
      active-border-color="#FFBC1B"
      active-color="#FFBC1B"
      inactive-color="#FFF"
      :border-width="starSize / 4"
      :rounded-corners="true"
      :show-rating="false"
    />
  </section>
</template>
